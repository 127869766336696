<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                    </div>
					<div class="d-flex flex-column">
						<b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collectGrowthSales">로켓그로스 판매현황 수집</b-button>
						<b-button variant="success pl-5 pr-5" class="mt-2 mr-1" v-b-modal.datalabAPI>데이터랩 API설정</b-button>
						<b-modal id="datalabAPI" size="sm" scrollable title="네이버 데이터랩 API설정" ok-only ok-title="닫기" class="detail-modal">
							<div>
								<p><a href="https://developers.naver.com/apps/#/register=datalab" target="_blank"><u>네이버개발자센터</u></a>에 접속해서 아래의 순서로 API key를 받은 후 입력하고 API저장을 눌러주세요</p>
								<card class="w-50 p-3">
									<div class="d-flex justify-content-start align-items-center w-100">
										<div class="w-25">Client ID : </div>
										<div class="w-75">
											<input type="text" class="form-control text-center" placeholder="Client ID" v-model="clientId">
										</div>
									</div>
									<div class="d-flex justify-content-start align-items-center w-100">
										<div class="w-25">Client Secret : </div>
										<div class="w-75">
											<input type="text" class="form-control text-center" placeholder="Client Secret" v-model="clientSecret">
										</div>
									</div>
									<b-button class="w-25 mt-3" variant="outline-success" @click="saveAPI">API저장</b-button>
								</card>
							</div>


							<img  style="max-width:100%;" :src="require('../../../assets/images/small/데이터랩api.png')">
						</b-modal>
					</div>

                </div>
			</div>
			<div class="col-lg-12">
                <img v-if="showspinner" style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                <div v-if="showspinner1" class="d-flex justify-content-center">
                    <div><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                </div>
            </div>
		</div>


		<div class="row mt-3">
			<div class="col-lg-12" v-if="showlist">
				<div class="row d-flex justify-content-between">
					<div>
						<b-button variant="outline-primary" class="ml-3 mb-1 mr-2" @click="showAll">
						전체<b-badge variant="primary" class="ml-2">{{totalCnt}}</b-badge>
						</b-button>
						<b-button variant="outline-secondary" class="mb-1 mr-2" @click="showNeedPo">
						구매 필요<b-badge variant="secondary" class="ml-2">{{needPo}}</b-badge>
						</b-button>
						<b-button variant="outline-success" class="mb-1 mr-2" @click="showMonthlyCntOver0">
						월판매0이상<b-badge variant="success" class="ml-2">{{monthlyCntOver0}}</b-badge>
						</b-button>
						<!-- <b-button variant="outline-danger" class="mb-1 mr-2" @click="showNeedReturn">
						반출 필요<b-badge variant="danger" class="ml-2">{{needReturn}}</b-badge>
						</b-button>  -->
						<b-button variant="outline-danger" class="mb-1 mr-2" @click="showReturn">
						반품제품<b-badge variant="danger" class="ml-2">{{returnProducts}}</b-badge>
						</b-button> 
						<div class="custom-control custom-switch custom-control-inline">
							<input type="checkbox" class="custom-control-input" id="customSwitchsss" v-model="showReturnProducts">
							<label class="custom-control-label" for="customSwitchsss">반품상품보기</label>
						</div>
					</div>
					<div>
						<button type="button" class="btn btn-primary mr-2 mb-4" @click="saveSales">저장</button>
						<button type="button" class="btn btn-danger mr-2 mb-4" v-b-toggle.my-collapse>일괄변경</button>
						<button type="button" class="btn btn-success mr-2 mb-4" v-b-modal.excelupload>재고일괄업로드</button>
						<button type="button" class="btn btn-secondary mr-2 mb-4" v-b-modal.reqpurchase>구매요청</button>
					</div>
				</div>
			</div>

			<div class="col-lg-12" v-if="showlist">
                <CDataTable 
					:headers="headers" 
					:items="saleslists" 
					v-model="tableSelectedItem" 
					:show-select="true" 
					:fixedColumns="'옵션명'"
					itemKey="_id" 
					style="margin-bottom: 10px"
				>
					<template v-slot:상품등급="{item}">
                        <div><a :href="'https://www.coupang.com/vp/products/' + item.productId + '?vendorItemId=' + item.vendorItemId" target="_blank"><b-badge variant="success">새제품</b-badge></a></div>
                        <div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">
                                <a :href="'https://www.coupang.com/vp/products/' + a.listingDetails.productId + '?vendorItemId=' + a.listingDetails.vendorItemId" target="_blank">
                                    <b-badge variant="secondary">
                                        {{ a.skuGrade == 'PACKAGE_DAMAGED' ? '반품-미개봉' :
                                            a.skuGrade == 'RETURN_BEST' ? '반품-최상' :
                                            a.skuGrade == 'RETURN_GOOD' ? '반품-상' : '반품-중'
                                        }}
                                    </b-badge>
                                </a>
                            </div>
                        </div>
                    </template>
					<template v-slot:썸네일="{item}">
                        <img :src="item.썸네일" class="img-fluid rounded avatar-40 mr-3" alt="image" v-b-modal="'poQty'+item.vendorItemId">
                    </template>
                    <template v-slot:판매상품명="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">{{item.판매상품명}}</div>
                    </template>
					<template v-slot:옵션명="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">{{item.옵션명}}</div>
                    </template>
					<template v-slot:vendorItemId="{item}">
                        <div class="d-flex align-items-center" v-b-modal="'poQty'+item.vendorItemId">{{item.vendorItemId}}</div>
						<div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">{{a.vendorItemId}}</div>
                        </div>
                    </template>
                    <template v-slot:타겟키워드="{item}">
                        <input type="text" class="form-control ml-1 text-center" placeholder="" v-model="item.타겟키워드" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
                    <template v-slot:구매필요수량="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">
                            {{item.구매필요수량}}
                        </div>
                        <b-modal :id="'poQty'+item.vendorItemId" scrollable title="구매필요수량" ok-title="OK" size="xl" class="detail-modal" @show="resetChartData(item)" ok-only @ok="closeModal($event,item)">
							<div class="row h-100">
								<div class="col-md-12">
									<card style="padding:10px;">
										<div class="d-flex align-items-center" style="margin:auto;padding:0px">
											<img :src="item.썸네일" class="img-fluid rounded avatar-120 mr-3" alt="image">
											<div>
												<p>판매상품명 : {{item.판매상품명}}</p>
												<p>노출ID : {{item.productId}}</p>
												<p>옵션ID : {{item.vendorItemId}}</p>
											</div>
										</div>
									</card>
								</div>
								<div class="col-md-8 h-100">
									<b-card no-body>
										<b-card-body class="p-3"> <!-- padding을 제거합니다 -->
											<div style="width: 100%; height:100%"> <!-- 100% 너비의 div로 감쌉니다 -->
												<ApexChart ref="mainChart" element="layout1-chart-5" :chartOption="getUpdatedChartOption(item)" />
											</div>
										</b-card-body>
										<b-card-footer>
											<div class="d-flex justify-content-between align-items-center row">
												<div class="col-6 d-flex justify-content-start align-items-center">
													<label class="w-50">판매인식기간:</label>
													<b-form-input class="w-50" type="date" v-model="item.startDate" @change="updateDateRange(item)"></b-form-input>
													<h5> ~ </h5>
													<b-form-input class="w-50" type="date" v-model="item.endDate" @change="updateDateRange(item)"></b-form-input>
												</div>
												<div class="col-4 d-flex justify-content-start align-items-center">
													<b-button variant="outline-primary" size="md" @click="setDateRange(item, 7)">최근 7일</b-button>
													<b-button variant="outline-primary" size="md" @click="setDateRange(item, 30)" class="ml-2">최근 30일</b-button>
												</div>
											</div>
										</b-card-footer>
									</b-card>
								</div>
								<div class="col-md-4 h-100">
									<b-card>
										<b-list-group flush>
											<!-- 판매 정보 -->
											<b-list-group-item class="p-2">
												<div class="d-flex justify-content-between">
													<div class="font-weight-bold text-primary">판매 정보</div>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom">
												<div class="d-flex justify-content-between align-items-center">
													<span>일평균판매량</span>
													<span class="font-weight-bold">{{ item.일판매수량.toFixed(1) }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom">
												<div class="d-flex justify-content-between align-items-center">
													<span>현재재고수량</span>
													<span class="font-weight-bold">{{ item.재고 }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1">
												<div class="d-flex justify-content-between align-items-center">
													<span>남은 재고기간</span>
													<span class="font-weight-bold">{{ item.남은재고기간 }}</span>
												</div>
											</b-list-group-item>

											<!-- 예측 정보 -->
											<b-list-group-item class="p-2 mt-2">
												<div class="d-flex justify-content-between">
													<div class="font-weight-bold text-primary">예측 정보</div>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom">
												<div class="d-flex justify-content-between align-items-center">
													<span>향후 30일 예상 판매수량(series)</span>
													<span class="font-weight-bold">{{ parseInt(item.forecastSeriesQty) }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1">
												<div class="d-flex justify-content-between align-items-center">
													<span>향후 30일 예상 판매수량(AI)</span>
													<span class="font-weight-bold">{{ parseInt(item.forecastAIQty) }}</span>
												</div>
											</b-list-group-item>

											<!-- Series 기준 구매 정보 -->
											<b-list-group-item class="p-2 mt-2">
												<div class="d-flex justify-content-between">
													<div class="font-weight-bold text-primary">구매 정보 (Series 기준)</div>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom">
												<div class="d-flex justify-content-between align-items-center">
													<span>D-Day(Series)</span>
													<span class="font-weight-bold">{{ calculateSeriesDday(item) }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom">
												<div class="d-flex justify-content-between align-items-center">
													<span>구매필요수량(Series)</span>
													<span class="font-weight-bold">{{ calculateSeriesNeededQty(item) }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1">
												<div class="d-flex justify-content-between align-items-center">
													<span>필요재고 일수(Series)</span>
													<span class="font-weight-bold">{{ calculateSeriesNeededDays(item) }}</span>
												</div>
											</b-list-group-item>

											<!-- AI 기준 구매 정보 -->
											<b-list-group-item class="p-2 mt-2" v-if="item.forecastAI && item.forecastAI.length > 0">
												<div class="d-flex justify-content-between">
													<div class="font-weight-bold text-primary">구매 정보 (AI 기준)</div>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom" v-if="item.forecastAI && item.forecastAI.length > 0">
												<div class="d-flex justify-content-between align-items-center">
													<span>D-Day(AI)</span>
													<span class="font-weight-bold">{{ item.dday }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1 border-bottom" v-if="item.forecastAI && item.forecastAI.length > 0">
												<div class="d-flex justify-content-between align-items-center">
													<span>구매필요수량(AI)</span>
													<span class="font-weight-bold">{{ item.구매필요수량 }}</span>
												</div>
											</b-list-group-item>
											<b-list-group-item class="p-1" v-if="item.forecastAI && item.forecastAI.length > 0">
												<div class="d-flex justify-content-between align-items-center">
													<span>필요재고 일수(AI)</span>
													<span class="font-weight-bold">{{ calculateNeededStockDays(item) }}</span>
												</div>
											</b-list-group-item>

											<!-- 구매 기준 설명 -->
											<b-list-group-item class="p-1 mt-2">
												<small class="text-secondary">
													※ 재고 기준:
													<br>- 안전재고(7일) 이하 → 20일치 주문
													<br>- 7~27일 사이 → 부족분 주문
													<br>- 27일 이상 → 주문 없음
												</small>
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</div>
							</div>
						</b-modal>
                    </template>
					<template v-slot:구매="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="구매" v-model="item.구매" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
                    <template v-slot:배대지="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="배대지" v-model="item.배대지" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
                    <template v-slot:입고중="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">{{item.입고중}}</div>
                    </template>
					<template v-slot:통관중="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="통관중" v-model="item.통관중" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
					<template v-slot:국내재고="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="국내재고" v-model="item.국내재고" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
                    <template v-slot:입고기간="{item}">
                        <input type="number" class="form-control ml-1 text-center" placeholder="입고기간" v-model="item.입고기간" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
                    <template v-slot:최대재고기간="{item}">
                        <input type="number" class="form-control ml-1 text-center" placeholder="최대재고기간" v-model="item.최대재고기간" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
                    <template v-slot:최소재고기간="{item}">
                        <input type="number" class="form-control ml-1 text-center" placeholder="최소재고기간" v-model="item.최소재고기간" @keyup.enter="changePoEnter($event,item)" @input="markAsModified(item)">
                    </template>
					<template v-slot:재고="{item}">
                        <div style="font-weight:bold;color:white;background-color:#60b17c" v-b-modal="'poQty'+item.vendorItemId">{{item.재고}}</div>
						<div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">{{a.orderableQuantity}}</div>
                        </div>
                    </template>
					<template v-slot:남은재고기간="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">{{item.남은재고기간}}</div>
						<div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">{{a.남은재고기간}}</div>
                        </div>
                    </template>
                    <template v-slot:월판매수량="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">{{item.월판매수량}}</div>
						<div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">{{a.unitsSoldForLast30Days}}</div>
                        </div>
                    </template>
                    <template v-slot:일판매수량="{item}">
                        <div v-b-modal="'poQty'+item.vendorItemId">{{item.일판매수량.toFixed(1)}}</div>
                    </template>
					<template v-slot:최대입고기간="{item}">
                        <div v-b-modal="'inboundDetails'+item.vendorItemId" style="cursor: pointer">{{item.최대입고기간}}</div>
						<div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">{{a.최대입고기간}}</div>
                        </div>
                    </template>
					<template v-slot:보관료="{item}">
                        <div v-b-modal="'inboundDetails'+item.vendorItemId" style="cursor: pointer">{{item.보관료}}</div>
						<div v-if="item.returnProducts && item.returnProducts.length > 0 && showReturnProducts">
                            <div v-for="a,i in item.returnProducts" :key="i">{{a.보관료}}</div>
                        </div>

						<!-- 입고 상세 내역 모달 -->
						<b-modal :id="'inboundDetails'+item.vendorItemId" 
							scrollable 
							title="입고 상세 내역" 
							ok-only
						>
							<div class="overflow-x-auto">
								<!-- 입고 상세 테이블 -->
								<table class="table table-bordered">
									<thead>
										<tr>
											<th class="text-center">입고일자</th>
											<th class="text-center">남은수량</th>
											<th class="text-center">입고기간</th>
											<!-- <th class="text-center">보관료</th> -->
										</tr>
									</thead>
									<tbody>
										<tr v-for="(detail, index) in sortedInboundList(item.inboundInfo)" 
											:key="index"
											:class="{ 'text-muted': detail.remainingSkuQty <= 0 }">
											<td class="text-center">{{ formatDate(detail.inboundDate) }}</td>
											<td class="text-right">{{ formatNumber(detail.remainingSkuQty) }}개</td>
											<td class="text-right">{{ calculateDaysAgo(detail.inboundDate) }}일</td>
											<!-- <td class="text-right">{{ formatCurrency(calculateStorageFee(detail.inboundDate, detail.remainingSkuQty, item.inboundInfo.storageFeeRates)) }}</td> -->
										</tr>
										<!-- 합계 행 -->
										<tr class="table-secondary font-weight-bold">
											<td>합계</td>
											<td class="text-right">{{ formatNumber(getTotalQuantity(item.inboundInfo)) }}개</td>
											<td></td>
											<!-- <td class="text-right">{{ formatCurrency(getTotalStorageFee(item.inboundInfo)) }}</td> -->
										</tr>
									</tbody>
								</table>
							</div>
						</b-modal>

                    </template>
                    <template v-slot:메모="{item}">
                        <input type="text" class="form-control ml-1 text-center" placeholder="" v-model="item.메모" @keyup.enter="changeMemo($event,item)" @input="markAsModified(item)">
                    </template>
                </CDataTable>
			
			

			<b-collapse id="my-collapse">
				<b-card title="">
					<div class="form-group">
						<input type="number" class="form-control" placeholder="입고기간" v-model="입고기간">
					</div>
					<div class="form-group">
						<input type="number" class="form-control" placeholder="안전재고일" v-model="최소재고기간">
					</div>
					<div class="form-group">
						<input type="number" class="form-control" placeholder="목표재고일" v-model="최대재고기간">
					</div>
					<button type="button" class="btn btn-success mr-2 mt-1" @click="editlists">적용</button>
				</b-card>
			</b-collapse> 
			
			<b-modal size="sm" id="excelupload" scrollable title="재고현황 일괄업로드" ok-only>
                <div class="row">
                    <div class="col-md-12">
                        <!-- <p><b style="color:red">1688을 통해 구매하지 않거나, 별도 재고로 관리하는 사입제품의 경우, 구매링크를 '사입'이라고 표시해 주세요</b></p>
                        <p>신규상품, 발주상태 등 상품 내역에 변경사항이 있을 경우 서플라이어에 접속한 후 <u v-b-modal.modal-3>크롬확장프로그램</u>으로 SKU 리스트를 먼저 수집해 주세요</p> -->
                        <p>[업로드 방법]</p>
                        <p>1. 엑셀업로드 포맷 다운로드<br>
                        2. 엑셀 파일에서 옵션ID,구매,배대지,통관중,국내재고 업데이트<br>
                        3. 업데이트된 엑셀 파일 업로드 순으로 진행해 주세요.</p>

                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="form-group">
                            <label>1. 엑셀업로드 포맷</label>
                            <div>
                            <button type="reset" @click="formatDownload" class="btn btn-primary mr-2">Download</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label  for="Code">2. 업데이트된 재고현황엑셀파일 업로드</label>
                            <div class="custom-file">
                            <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                            <label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
                            </div>
                            <div class="col-md-12" v-if="showuploadspinner">
                                <div>
                                    <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

			<b-modal id="reqpurchase" size="xl" scrollable title="구매대행요청" ok-title="구매대행요청추가" cancel-title="취소" class="detail-modal" @ok="requestpurchase($event)" @show="initReqLists">
				<CDataTable 
					:headers="reqHeaders" 
					:items="reqlists"
					v-model="reqTableSelectedItem" 
					:show-select="true" 
					itemKey="vendorItemId"
					style="margin-bottom: 10px"
				>
					<template v-slot:상품명="{item}">
						<div class="d-flex align-items-center">
							<img :src="item.썸네일" class="img-fluid rounded avatar-40 mr-3" alt="image">
							<div class="text-wrap">{{item.판매상품명}}</div>
						</div>
					</template>
					<template v-slot:구매요청수량="{item}">
						<input 
							type="number" 
							class="form-control text-center" 
							v-model="item.구매요청수량"
							min="0"
						>
					</template>
				</CDataTable>
			</b-modal>

			<b-modal id="waitreqpurchase" size="sm"  hide-footer>
				<div class="text-center">
					구매요청 내역을 저장 중입니다. 잠시만 기다려 주세요.
				</div>
				<div class=text-center>
					<img style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
				</div>
			</b-modal>


			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import ApexChart from '../../../components/charts/ApexChart';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
export default {
	name:'salesstatus',
	components:{
        ApexChart,
        CDataTable
    },
	data(){
		return{
			headers: [
				{text: '상품\n등급', value: '상품등급', align: 'center', width: 50, isSlot: true},
				{text: '상품\n이미지', value: '썸네일', align: 'center', width: 50, isSlot: true},
				{text: '상품명', value: '판매상품명', align: 'center', width: 300, isSlot: true},
				{text: '옵션명', value: '옵션명', align: 'center', width: 150, isSlot: true},
				{text: '옵션ID', value: 'vendorItemId', align: 'center', width: 80, isSlot: true},
				
				{text: '구매\nD-Day', value: 'dday', align: 'center', width: 80, isSlot: false},
				{text: '구매\n필요수량', value: '구매필요수량', align: 'center', width: 80, isSlot: true},
				{text: '월판매\n수량', value: '월판매수량', align: 'center', width: 80, isSlot: true},
				{text: '일판매\n수량', value: '일판매수량', align: 'center', width: 80, isSlot: true},
				{text: '최대\n입고기간', value: '최대입고기간', align: 'center', width: 80, isSlot: true},
				{text: '보관료', value: '보관료', align: 'center', width: 100, isSlot: true},
				
				{text: '쿠팡재고', value: '재고', align: 'center', width: 80, isSlot: true},
				{text: '구매', value: '구매', align: 'center', width: 100, isSlot: true},
				{text: '배대지', value: '배대지', align: 'center', width: 100, isSlot: true},
				{text: '통관중', value: '통관중', align: 'center', width: 100, isSlot: true},
				{text: '국내재고', value: '국내재고', align: 'center', width: 100, isSlot: true},
				{text: '입고중', value: '입고중', align: 'center', width: 80, isSlot: true},
                
                {text: '남은\n재고기간', value: '남은재고기간', align: 'center', width: 80, isSlot: true},
                {text: '입고기간', value: '입고기간', align: 'center', width: 100, isSlot: true},
                {text: '목표\n재고일', value: '최대재고기간', align: 'center', width: 100, isSlot: true},
                {text: '안전\n재고일', value: '최소재고기간', align: 'center', width: 100, isSlot: true},

				{text: '타겟\n키워드', value: '타겟키워드', align: 'center', width: 120, isSlot: true},
                {text: '메모', value: '메모', align: 'center', width: 100, isSlot: true},
            ],
			tableSelectedItem: [],
			reqHeaders: [
				{text: '상품명', value: '상품명', align: 'left', width: 300, isSlot: true},
				{text: '옵션명', value: '옵션명', align: 'left', width: 150},
				{text: '옵션ID', value: 'vendorItemId', align: 'center', width: 100},
				{text: '구매', value: '구매', align: 'center', width: 80},
				{text: '배대지', value: '배대지', align: 'center', width: 80},
				{text: '통관중', value: '통관중', align: 'center', width: 80},
				{text: '국내재고', value: '국내재고', align: 'center', width: 80},
				{text: '쿠팡재고', value: '재고', align: 'center', width: 80},
				{text: '구매요청수량', value: '구매요청수량', align: 'center', width: 120, isSlot: true}
			],
			reqTableSelectedItem: [],
			profitTop10ChartOption: null,
			clientId: this.$store.state.settings[0].clientId ? this.$store.state.settings[0].clientId : '',
			clientSecret: this.$store.state.settings[0].clientSecret ? this.$store.state.settings[0].clientSecret : '',
			needPo:0,
			needPriceAdjust:0,
			salesAmount:0,
			marginAmount:0,
			monthlyCntOver0:0,

			입고기간:"",
			최소재고기간:"",
			최대재고기간:"",

			reqlists:[],

			loadingtext:'',
			showspinner1:false,
			
			baseSettings: {
				safetyDays: 7,    // 안전재고일
				targetDays: 27,   // 목표재고일
				inputDays:17,     // 기본입고기간
				maxOrderDays: 20  // 최대주문일수 (targetDays - safetyDays)
			},
			showReturnProducts:false,
			returnProducts:0,
			totalCnt:0,

			showuploadspinner:false,
			uploadstatus:'excel file upload',

			salesarr:[],
			importarr:[],
			stockarr:[],

			salescnt:0,
			importcnt:0,
			productqty:0,
			needReturn:0,


			title:"로켓그로스 판매현황 및 SCM 관리",
			desc1:"로켓그로스의 판매현황을 확인하고 수요에 맞는 공급관리(SCM)를 진행하세요",
			desc2:"입고기간 : 입고하는 데까지 걸리는 기간",
			desc3:"최소재고기간 : 최소한의 재고가 남아있어야 하는 기간, 최대재고기간 : 구매 시 필요한 재고기준 기간",
			desc4:"",
			link:{ name: 'people.addusers'},
			linktext:"그로스 판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getTodaywith(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,
			
			showChart: false,
			conversionChartOption: null,  // 추가
			maxCpcChartOption: null,      // 추가
			selectedPeriod: 30,
			dailyStats: [],
			revenueChartOption: {
				chart: {
					type: 'line',
					height: 350,
					animations: {
						enabled: false
					},
					toolbar: {
						show: false
					},
					background: 'transparent'
				},
				series: [{
					name: '매출',
					type: 'line',
					data: []
				}, {
					name: '수익',
					type: 'line',
					data: []
				}],
				stroke: {
					curve: 'smooth',
					width: [2, 2]
				},
				colors: ['#008FFB', '#00E396'],
				dataLabels: {
					enabled: false
				},
				grid: {
					show: true,
					borderColor: '#e0e0e0',
					strokeDashArray: 0,
					position: 'back'
				},
				xaxis: {
					type: 'category',
					categories: [],
					labels: {
						rotate: -45,
						rotateAlways: false,
						style: {
							fontSize: '12px'
						}
					},
					axisBorder: {
						show: true
					},
					axisTicks: {
						show: true
					}
				},
				yaxis: {
					labels: {
						formatter: (value) => `￦${this.formatNumber(value)}`,
						style: {
							fontSize: '12px'
						}
					}
				},
				tooltip: {
					enabled: true,
					shared: true,
					intersect: false,
					y: {
						formatter: (value) => `￦${this.formatNumber(value)}`
					}
				},
				legend: {
					position: 'top',
					horizontalAlign: 'right',
					floating: true,
					offsetY: -25,
					offsetX: -5
				}
			}
		}
	},
	computed: {
		productCount() {
		return this.saleslistsinit.length;
		},
		monthlyRevenue() {
		return this.dailyStats.reduce((sum, day) => sum + day.revenue, 0);
		},
		monthlyProfit() {
		return this.dailyStats.reduce((sum, day) => sum + day.profit, 0);
		},
		profitRate() {
		return this.monthlyRevenue ? 
			((this.monthlyProfit / this.monthlyRevenue) * 100).toFixed(1) : 0;
		},
		bestRevenue() {
		return Math.max(...this.dailyStats.map(day => day.revenue));
		},
		bestProfit() {
		return Math.max(...this.dailyStats.map(day => day.profit));
		},
		bestRevenueDate() {
		const bestDay = this.dailyStats.find(day => day.revenue === this.bestRevenue);
		return bestDay ? this.formatDate(bestDay.date) : '-';
		},
		bestProfitDate() {
		const bestDay = this.dailyStats.find(day => day.profit === this.bestProfit);
		return bestDay ? this.formatDate(bestDay.date) : '-';
		},
		averageDailyRevenue() {
		return this.dailyStats.length ? 
			this.monthlyRevenue / this.dailyStats.length : 0;
		},
		revenueChartOptionWithAnnotations() {
			if (!this.revenueChartOption) return null;

			return {
				...this.revenueChartOption,
				annotations: {
					xaxis: [{
						x: this.bestRevenueDate,
						borderColor: '#008FFB',
						label: {
							text: '최고 매출',
							style: {
								background: '#008FFB',
								color: '#fff'
							}
						}
					}, {
						x: this.bestProfitDate,
						borderColor: '#00E396',
						label: {
							text: '최고 수익',
							style: {
								background: '#00E396',
								color: '#fff'
							}
						}
					}]
				}
			};
		}
	},
	async mounted() {
		try {
			await this.search();
		} catch (error) {
			console.error('mounted 훅 실행 중 오류:', error);
		}

		this.messageEventHandler = async(event) => {
			if (event.data.msg && event.data.msg === 'growthSales') {
				if (event.data.res === 'success') {
					this.showlist = false;
					this.loadingtext = '수집 상품 저장 중 ...';
					
					const stockInfo = event.data.stockInfo.filter(e => e?.listingDetails?.skuGrade == 'NEW');
					const returnList = event.data.stockInfo.filter(e => e?.listingDetails?.skuGrade !== 'NEW');

					if (stockInfo.length > 0) {
						const sales = [];
						for (const e of stockInfo) {
							if (e.trafficInfo) {
								var salesHistory = e.trafficInfo.saleSummaryByDate.map(e => e.unitsSold).slice(-30);
								var visitorHistory = e.trafficInfo.trafficSummaryByDate.map(e => e.uniqueVisitor).slice(-30);
								var pageViewHistory = e.trafficInfo.trafficSummaryByDate.map(e => e.pageViews).slice(-30);
								var cartHistory = e.trafficInfo.conversionSummaryByDate.map(e => e.addToCart).slice(-30);
								var orderHistory = e.trafficInfo.conversionSummaryByDate.map(e => e.orders).slice(-30);
								var pvToOrder = e.trafficInfo.conversionSummaryByDate.map(e => Math.round(e.pvToOrder*1000)/10).slice(-30);
								var prevMonthlyClicks = e.trafficInfo.trafficSummaryByDate.map(e => e.pageViews).slice(30).reduce((pv,cv)=>pv + cv,0);
								var currentMonthlyClicks = pageViewHistory.reduce((pv,cv)=>pv + cv,0);
							} else {
								salesHistory = new Array(30).fill(0);
								visitorHistory = new Array(30).fill(0);
								pageViewHistory = new Array(30).fill(0);
								cartHistory = new Array(30).fill(0);
								orderHistory = new Array(30).fill(0);
								pvToOrder = new Array(30).fill(0);
								prevMonthlyClicks = 0;
								currentMonthlyClicks = 0;
							}

							const 일판매수량 = salesHistory.reduce((pv,cv)=>pv+cv,0)/30;
							const 월판매수량 = salesHistory.reduce((pv,cv)=>pv+cv,0);
							// const 재고 = e.orderableQty || 0;
							const 입고중 = e.inventoryDetails?.inProgressInboundStatistics?.inProgressInboundQuantity || 0;

							const returnProducts = returnList.filter(ele => 
								ele.listingDetails.vendorInventoryName == e.listingDetails.vendorInventoryName &&
								ele.listingDetails.vendorInventoryItemName == e.listingDetails.vendorInventoryItemName
							).sort((a,b) => b.salePrice - a.salePrice);

							sales.push({
								썸네일: "http://image1.coupangcdn.com/image/" + e.listingDetails.mainImagePath,
								판매상품명: e.listingDetails.vendorInventoryName,
								옵션명: e.listingDetails.vendorInventoryItemName,
								productId: e.listingDetails.productId,
								vendorItemId: e.vendorItemId,
								재고: e.orderableQuantity,
								입고중,
								월판매수량,
								일판매수량,
								salesHistory,
								visitorHistory,
								pageViewHistory,
								cartHistory,
								orderHistory,
								pvToOrder,
								prevMonthlyClicks,
								currentMonthlyClicks,
								updateDate: e.updateDate,
								inventoryDetails: e.inventoryDetails,
								inboundInfo: e.inboundInfo,
								returnProducts,
								skuGrade: 'NEW'
							});
						}

						try {
							const result = await axios.post('/api/updateGrowthSalesV2', sales);
							if (result.data.res === 'success') {
								this.$store.commit('growthSalesUpdate', sales);
								this.searchresult(sales);
							} else {
								alert(result.data.alert);
								this.showspinner1 = false;
								this.showlist = true;
							}
						} catch (err) {
							alert(err);
							this.showspinner1 = false;
							this.showlist = true;
						}
					} else {
						alert('이미 업데이트가 완료된 상태입니다.');
						this.showspinner1 = false;
						this.showlist = true;
					}
				} else {
					alert(event.data.res);
					this.showspinner1 = false;
					this.showlist = true;
				}
			}
			if (event.data.res && event.data.res === 'growthdbratio') {
				if (event.data.ratio == 0) {
					this.loadingtext = event.data.text;
				} else {
					this.loadingtext = event.data.text + event.data.ratio.toFixed(0) + '%';
				}
			}
		};
		window.addEventListener('message', this.messageEventHandler);
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	methods: {
		initReqLists() {
			this.reqlists = this.tableSelectedItem.length > 0 
				? this.tableSelectedItem.map(item => ({
					...item,
					구매요청수량: item.구매필요수량
				}))
				: this.saleslists.map(item => ({
					...item,
					구매요청수량: item.구매필요수량
				}));
		},
		async requestpurchase($event){
            $event.preventDefault();
            this.$bvModal.show('waitreqpurchase');
			var lists = this.reqTableSelectedItem.length > 0 ? this.reqTableSelectedItem : this.reqlists;
			
			if(lists.length == 0){
				alert('구매대행요청할 상품이 없습니다.')
				this.$bvModal.hide('waitreqpurchase');
				this.$bvModal.hide('reqpurchase');
				return
			}
			var arr = [];
			console.log(lists)
			for(var i=0;i<lists.length;i++){
				var e = lists[i];
				var addlist = await this.addlists(e);
				arr = arr.concat(addlist);
			}
			if(arr.length == 0){
				alert('구매대행요청할 상품이 없습니다.')
				this.$bvModal.hide('waitreqpurchase');
				this.$bvModal.hide('reqpurchase');
				return
			}
			await this.addreqlist(arr)
        },
        async addreqlist(lists){
            console.log(lists)
            try{
                var res = await axios.post('/api/reqpurchase/addreqlist',lists)

				if(res.data.요청결과 == "저장완료"){
					if(confirm('저장이 완료되었습니다. 구매요청메뉴로 이동하시겠습니까?')){
						this.$router.push({name:'nomad.nomadreqpo'})
					}
				} else {
					alert(res.data.요청결과);
				}
                console.log(res)
            }catch(e){
                console.log(e)
            }
            this.$bvModal.hide('waitreqpurchase');
            this.$bvModal.hide('reqpurchase');
        },
        async addlists(e){
            var ob = this.$store.state.growthdb.find(ele => ele.vendorItemId == e.vendorItemId);
			console.log(ob)
            if(ob && ob.구매정보 && ob.구매정보.length > 0 && !ob.구매정보.find(f => f.매칭상태 !== '매칭완료' || f.요청사항 !== '등록완료')){
				var arr = [];
				for(var obj of ob.구매정보){
					var newObj = await this.confirmreq(obj);
					newObj.구매요청수량 = e.구매요청수량;
					arr.push(newObj);
				}	
				return arr;
			} else {
				return [{
                            checked : false,
                            showspinner : false,
                            // SKUID : '',
                            바코드 : ob.barcode,
                            상품명 : ob.skuName,
                            상품명init : ob.skuName,
                            구매링크 : undefined,
                            구매링크init : undefined,
                            옵션1_중국어 : undefined,
                            옵션2_중국어 : undefined,
                            옵션이미지: undefined,
                            매칭상태 : '매칭필요',
                            상품매칭 : {
                                옵션1_중국어 : [],
                                옵션2_중국어 : [],
                            },
                            구매재고 : '',
                            구매요청수량 : e.구매요청수량,
                            판매구성수량 : 1,
                            묶음구성여부 : false,
                            묶음구성수량 : '',
                            묶음대상바코드 : '',
                            신고영문명 : '',
                            신고가격 : '',
                            HSCODE : '',
                            진행현황 : '구매전',
                            재고동봉 : true,
                            등록이미지 : ob.imgUrl,
                            요청사항 : '요청등록',
                            reqtype : '제트배송',
                            sizeX : ob.skuInfo ? ob.skuInfo.width : 0,
                            sizeY : ob.skuInfo ? ob.skuInfo.length : 0,
                            sizeZ : ob.skuInfo ? ob.skuInfo.heigth : 0,
                            weight : ob.skuInfo ? ob.skuInfo.weight : 0,
                            요청내역 : {
                                default : true,
                                poarrange : false,
                                한글표시사항 : true,
                                barcode : true,
                                sticker : false,
                                stamp : false,
                                tag : false,
                                reqcontent : '',
                                opp : false,
                                LDPE : false,
                                airpacking : false,
                                exportType : 'box',

                                masking : false,
                                destination : 'coupang',
                                auto : true,
                            },
                            한글표시사항 : {
                                제품명 : ob.skuName,
                                '수입원/판매원' : '',
                                제조원 : '',
                                제조국 : '',
                                내용량 : '',
                                '원료명및성분명(재질)' : '',
                                상품유형 : '',
                                사용시주의사항 : '',
                                사용기준 : '',
                                custom : '',
                            },
                            polists : [],
                            selectpo : '', 
                        }]
			}
        },
        async confirmreq(obj){
			obj.showspinner = true;
            console.log(obj)
            if(!obj.구매링크){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            item = item.data.result[0].result.result
            console.log(item)
            obj.item = item;

            let option1array = [];
            let option2array = [];

            if (item.productSkuInfos) {
                item.productSkuInfos.forEach((e) => {
                    if (!option1array.find(option => option.name === e.skuAttributes[0].value)) {
                        option1array.push({
                            name: e.skuAttributes[0].value,
                            imageUrl: e.skuAttributes[0].skuImageUrl ? e.skuAttributes[0].skuImageUrl : '',
                        })
                    }

                    if (e.skuAttributes[1]) {
                        if (!option2array.find(option => option.name === e.skuAttributes[1].value)) {
                            option2array.push({
                                name: e.skuAttributes[1].value,
                                imageUrl: e.skuAttributes[1].skuImageUrl ? e.skuAttributes[1].skuImageUrl : '',
                            })
                        }
                    } else {
                        option2array = []
                    }
                })
            }

            if(option1array.length === 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = item.productSaleInfo.amountOnSale;
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.productImage.images[0];
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.productImage.images[0];
                obj.매칭상태 = '매칭완료'
            } else {
                var imgUrl = '';
                if(option2array.length == 0){
                    imgUrl = option1array.find(e => e.name == obj.옵션1_중국어) ? imgUrl = option1array.find(e => e.name == obj.옵션1_중국어).imageUrl : "";
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        obj.구매재고 = item.productSkuInfos.find(f => f.skuAttributes[0].value == obj.옵션1_중국어).amountOnSale;
                        obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                    } else {
                        obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : option1array.find(e => e.name == obj.옵션1_중국어).imageUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                        } else {
                            obj.구매재고 = item.productSkuInfos.find(f => f.skuAttributes[0].value == obj.옵션1_중국어 && f.skuAttributes[1].value == obj.옵션2_중국어).amountOnSale;
                            imgUrl = option1array.find(e => e.name == obj.옵션1_중국어).imageUrl ? option1array.find(e => e.name == obj.옵션1_중국어).imageUrl : (option2array.find(e => e.name == obj.옵션2_중국어).imageUrl ? option2array.find(e => e.name == obj.옵션2_중국어).imageUrl : "");
                            obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : (option1array.find(e => e.name == obj.옵션1_중국어).imageUrl ? option1array.find(e => e.name == obj.옵션1_중국어).imageUrl : (option2array.find(e => e.name == obj.옵션2_중국어).imageUrl ? option2array.find(e => e.name == obj.옵션2_중국어).imageUrl : "") );
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    console.log(option1array)
                    console.log(option1array.find(e => e.name == obj.옵션1_중국어))
                    console.log(option2array)

                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }

                if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                    obj.등록이미지 = item.productImage.images[0];
                }
            }
            obj.showspinner = false;
            return obj
        },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            try{
                var res = await axios.post('/api/aiPage/getProductInfo',[{productId:parseInt(productNo)}])
                return res
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
		formatDownload(){
            var arr = [];
            var myHeader = ["상품명","옵션명","옵션ID",'구매','배대지','통관중','국내재고'];

			for(var e of this.saleslistsinit){
				arr.push({
					"상품명":e.판매상품명 || "",
					"옵션명":e.옵션명 || "",
					"옵션ID":e.vendorItemId || "",
					"구매":e.구매 || 0,
					"배대지":e.배대지 || 0,
					"통관중":e.통관중 || 0,
					"국내재고":e.국내재고 || 0
				})
			}

            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '재고현황');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '재고현황업로드양식.xlsx');
        },
		markAsModified(item) {
			item.isModified = true;
		},
		calculateInventoryMetrics(item) {
			// 기본 input 값 변환
			item.구매 = parseInt(item.구매) || 0;
			item.배대지 = parseInt(item.배대지) || 0;
			item.통관중 = parseInt(item.통관중) || 0;
			item.입고중 = parseInt(item.입고중) || 0;
			item.국내재고 = parseInt(item.국내재고) || 0;

			// 가용재고 계산 - 입고중 제외
			const 가용재고 = item.재고 + item.구매 + item.배대지 + item.통관중 + item.국내재고;

			// 메트릭 계산 및 적용
			if (item.일판매수량 <= 0) {
				return {
					남은재고기간: 0,
					구매필요수량: 0,
					dday: -999  // 일판매수량이 0일 때만 -999
				};
			}

			// 입고기간 동안의 예상 판매량 계산
			const 입고기간 = parseInt(item.입고기간) || this.baseSettings.inputDays;
			const 입고기간판매예상 = Math.ceil(item.일판매수량 * 입고기간);

			// 남은재고기간 계산
			let 남은재고기간 = 0;
			let 잔여가용재고 = 가용재고;

			// 예측 데이터 선택 (AI 기반 우선)
			const forecastData = (item.forecastAI && item.forecastAI.length > 0) ? 
				item.forecastAI : item.forecastSeries;

			for (const 예상판매량 of forecastData) {
				if (잔여가용재고 >= 예상판매량) {
					잔여가용재고 -= 예상판매량;
					남은재고기간++;
				} else {
					break;
				}
			}

			// 구매필요수량 계산
			let 구매필요수량 = 0;
			const 목표재고수량 = Math.ceil(this.baseSettings.targetDays * item.일판매수량);

			if (가용재고 <= 입고기간판매예상) {
				// 입고기간 동안의 판매량보다 재고가 적으면
				구매필요수량 = Math.max(입고기간판매예상 - 가용재고, 목표재고수량 - 가용재고);
			} else if (남은재고기간 <= this.baseSettings.safetyDays) {
				// 안전재고 이하면 목표재고일 기준으로 구매
				구매필요수량 = 목표재고수량 - 가용재고;
			} else if (남은재고기간 < this.baseSettings.targetDays) {
				// 목표재고일까지 부족분 구매
				구매필요수량 = 목표재고수량 - 가용재고;
			}

			// 구매필요수량이 음수면 0으로 설정
			구매필요수량 = Math.max(0, 구매필요수량);

			// D-Day 계산 수정 - 구매필요수량이 0일 때도 정상적으로 계산
			const dday = Math.ceil(입고기간 - (남은재고기간 - this.baseSettings.safetyDays));

			return {
				남은재고기간,
				구매필요수량,
				dday
			};
		},
		calculateMetricsForProduct({
			가용재고,
			일판매수량,
			forecastSeries,
			forecastAI,
			입고기간,
			최대재고기간,
			최소재고기간
		}) {
			const result = {
				남은재고기간: 0,
				구매필요수량: 0,
				dday: -999
			};

			if (!일판매수량 || 일판매수량 <= 0) {
				return result;
			}

			// 예측 데이터를 사용할지 여부 결정 (AI 기반 우선)
			const forecastData = (forecastAI && forecastAI.length > 0) ? forecastAI : forecastSeries;

			// 남은재고기간 계산
			let 남은재고기간 = 0;
			let 잔여가용재고 = 가용재고;

			for (const 예상판매량 of forecastData) {
				if (잔여가용재고 >= 예상판매량) {
					잔여가용재고 -= 예상판매량;
					남은재고기간++;
				} else {
					break;
				}
			}

			result.남은재고기간 = 남은재고기간;

			// D-Day 계산
			result.dday = Math.ceil(입고기간 - (남은재고기간 - 최소재고기간));

			// 구매 필요 수량 계산
			if (남은재고기간 <= 최소재고기간) {
				// 남은재고기간 ≤ safetyDays: 목표재고일을 충족하기 위해 구매
				result.구매필요수량 = Math.ceil(this.baseSettings.targetDays * 일판매수량 - 가용재고);
			} else if (남은재고기간 < 최대재고기간) {
				// safetyDays < 남은재고기간 < targetDays: 목표재고일을 충족하기 위해 부족분 구매
				result.구매필요수량 = Math.ceil(this.baseSettings.targetDays * 일판매수량 - 가용재고);
			} else {
				// 남은재고기간 ≥ targetDays: 구매 필요 없음
				result.구매필요수량 = 0;
			}

			// 구매 필요 수량 최소값 설정 (선택적)
			result.구매필요수량 = Math.max(0, result.구매필요수량);

			return result;
		},
		updateMaxCpcChart() {
			if (!this.saleslistsinit || this.saleslistsinit.length === 0) return;

			const maxCpcData = this.saleslistsinit
				.map(item => {
					const dbItem = this.$store.state.growthdb.find(x => x.vendorItemId === item.vendorItemId);
					const margin = dbItem?.마진 || 0;
					const validPvToOrder = item.pvToOrder.filter(rate => rate > 0);
					const avgConversion = validPvToOrder.length > 0 
						? validPvToOrder.reduce((a, b) => a + b, 0) / validPvToOrder.length 
						: 0;
					
					return {
						name: item.판매상품명,
						maxCpc: Math.floor((margin * (avgConversion / 100)) / 10) * 10,
						margin,
						conversion: avgConversion
					};
				})
				.filter(item => item.maxCpc > 0)
				.sort((a, b) => b.maxCpc - a.maxCpc)
				.slice(0, 10);

			this.maxCpcChartOption = {
				chart: {
					type: 'bar',
					height: 280,
					toolbar: {
						show: false
					}
				},
				series: [{
					name: '최대 CPC',
					data: maxCpcData.map(item => parseFloat(item.maxCpc.toFixed(0)))
				}],
				plotOptions: {
					bar: {
						horizontal: true,
						barHeight: '70%',
						borderRadius: 4,
						dataLabels: {
							position: 'center'
						}
					}
				},
				colors: ['#775DD0'],
				dataLabels: {
					enabled: true,
					formatter: function(value) {
						return '￦' + value.toLocaleString();
					},
					style: {
						fontSize: '12px',
						colors: ['#fff'],
						fontWeight: 'bold'
					}
				},
				xaxis: {
					categories: maxCpcData.map(item => 
						item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name
					),
					labels: {
						rotate: -45,
						maxHeight: 150,
						style: {
							fontSize: '11px'
						}
					}
				},
				legend: {
					show: false
				},
				tooltip: {
					custom: function({ dataPointIndex }) {
						const data = maxCpcData[dataPointIndex];
						return `
							<div class="custom-tooltip" style="padding: 8px; max-width: 300px;">
								<div style="margin-bottom: 8px; word-break: break-word; line-height: 1.4;">${data.name}</div>
								<div>CPC한도: ￦${data.maxCpc.toLocaleString()}</div>
							</div>
						`;
					}
				}
			};
		},
		updateConversionChart() {
			if (!this.saleslistsinit || this.saleslistsinit.length === 0) return;
			
			const totalVisits = this.saleslistsinit.reduce((sum, item) => sum + item.currentMonthlyClicks, 0);

			const productData = this.saleslistsinit
				.map(item => {
					const validPvToOrder = item.pvToOrder.filter(rate => rate > 0);
					const avgPvToOrder = validPvToOrder.length > 0 
						? validPvToOrder.reduce((a, b) => a + b, 0) / validPvToOrder.length 
						: 0;
					
					const trafficShare = (item.currentMonthlyClicks / totalVisits) * 100;
					
					return {
						name: item.판매상품명,
						conversion: avgPvToOrder,
						traffic: trafficShare
					};
				})
				.sort((a, b) => b.conversion - a.conversion)
				.slice(0, 10);

			this.conversionChartOption = {
				chart: {
					type: 'bar',
					height: 280,
					stacked: false,
					toolbar: {
						show: false
					}
				},
				series: [{
					name: '전환율(%)',
					type: 'column',
					data: productData.map(item => parseFloat(item.conversion.toFixed(2)))
				}, {
					name: '트래픽 점유율(%)',
					type: 'line',
					data: productData.map(item => parseFloat(item.traffic.toFixed(2)))
				}],
				plotOptions: {
					bar: {
						columnWidth: '60%'
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: [0, 3],
					curve: 'smooth'
				},
				colors: ['#00E396', '#008FFB'],
				xaxis: {
					categories: productData.map(item => 
						item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name
					),
					labels: {
						rotate: -45,
						style: {
							fontSize: '11px'
						}
					}
				},
				yaxis: [{
					title: {
						text: '전환율(%)'
					},
					min: 0
				}, {
					opposite: true,
					title: {
						text: '트래픽 점유율(%)'
					},
					min: 0
				}],
				tooltip: {
					custom: function({ dataPointIndex }) {
						const data = productData[dataPointIndex];
						return `
							<div class="custom-tooltip" style="padding: 8px; max-width: 300px;">
								<div style="margin-bottom: 8px; word-break: break-word; line-height: 1.4;">${data.name}</div>
								<div>전환율: ${data.conversion.toFixed(2)}%</div>
								<div>트래픽 점유율: ${data.traffic.toFixed(2)}%</div>
							</div>
						`;
					}
				},
				legend: {
					position: 'top'
				}
			};
		},
		updateProfitTop10Chart() {
			if (!this.saleslistsinit || this.saleslistsinit.length === 0) return;

			const profitByProduct = this.saleslistsinit.reduce((acc, item) => {
				const dbItem = this.$store.state.growthdb.find(x => x.vendorItemId === item.vendorItemId);
				const monthlyProfit = item.월판매수량 * (dbItem?.마진 || 0);

				if (!acc[item.productId]) {
					acc[item.productId] = {
						name: item.판매상품명.split('[')[0].trim(), // 옵션정보 제거
						profit: 0
					};
				}
				acc[item.productId].profit += monthlyProfit;
				return acc;
			}, {});

			// 객체를 배열로 변환하고 정렬
			const profitData = Object.values(profitByProduct)
				.filter(item => item.profit > 0)
				.sort((a, b) => b.profit - a.profit)
				.slice(0, 10);

			this.profitTop10ChartOption = {
				chart: {
					type: 'bar',
					height: 280,
					toolbar: {
						show: false
					}
				},
				series: [{
					name: '수익',
					data: profitData.map(item => item.profit)
				}],
				plotOptions: {
					bar: {
						horizontal: true,
						barHeight: '70%',
						borderRadius: 4,
						dataLabels: {
							position: 'center'
						}
					}
				},
				colors: ['#008FFB'],
				dataLabels: {
					enabled: true,
					formatter: function(value) {
						return '￦' + value.toLocaleString();
					},
					style: {
						fontSize: '12px',
						colors: ['#fff'],
						fontWeight: 'bold'
					}
				},
				xaxis: {
					categories: profitData.map(item => 
						item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name
					),
					labels: {
						rotate: -45,
						maxHeight: 150,
						style: {
							fontSize: '11px'
						}
					}
				},
				legend: {
					show: false
				},
				tooltip: {
					custom: function({ dataPointIndex }) {
						const data = profitData[dataPointIndex];
						return `
							<div class="custom-tooltip" style="padding: 8px; max-width: 300px;">
								<div style="margin-bottom: 8px; word-break: break-word; line-height: 1.4;">${data.name}</div>
								<div>수익: ￦${data.profit.toLocaleString()}</div>
							</div>
						`;
					}
				}
			};
		},
		showAll() {
			this.showlist = false;
			this.saleslists = [...this.saleslistsinit];
			setTimeout(() => { this.showlist = true; }, 10);
		},
		// Series 기준 D-Day 계산
		calculateSeriesDday(item) {
			if (!item || item.일판매수량 <= 0) return -999;

			const 가용재고 = item.재고 + item.구매 + item.배대지 + item.통관중 + item.입고중 + item.국내재고;
			let series남은재고기간 = 0;
			let series잔여가용재고 = 가용재고;

			for (const 예상판매량 of item.forecastSeries) {
				if (series잔여가용재고 >= 예상판매량) {
					series잔여가용재고 -= 예상판매량;
					series남은재고기간++;
				} else {
					break;
				}
			}

			const series안전재고도달시점 = series남은재고기간 - this.baseSettings.safetyDays;
			return parseInt(item.입고기간) - series안전재고도달시점;
		},
		// Series 기준 구매필요수량 계산
		calculateSeriesNeededQty(item) {
			if (!item || item.일판매수량 <= 0) return 0;

			const 가용재고 = item.재고 + item.구매 + item.배대지 + item.통관중 + item.입고중 + item.국내재고;
			let series남은재고기간 = 0;
			let series잔여가용재고 = 가용재고;

			for (const 예상판매량 of item.forecastSeries) {
				if (series잔여가용재고 >= 예상판매량) {
					series잔여가용재고 -= 예상판매량;
					series남은재고기간++;
				} else {
					break;
				}
			}

			if (series남은재고기간 <= this.baseSettings.safetyDays) {
				// 안전재고 이하면 최대주문일수만큼 구매
				const 최대주문일수 = this.baseSettings.targetDays - this.baseSettings.safetyDays;
				return Math.ceil(
					item.forecastSeries.slice(0, 최대주문일수).reduce((sum, val) => sum + val, 0)
				);
			} else if (series남은재고기간 < this.baseSettings.targetDays) {
				// 목표재고일까지 부족한 만큼 구매
				const 필요일수 = this.baseSettings.targetDays - series남은재고기간;
				const startIndex = series남은재고기간;
				const endIndex = Math.min(startIndex + 필요일수, item.forecastSeries.length);
				return Math.ceil(
					item.forecastSeries.slice(startIndex, endIndex).reduce((sum, val) => sum + val, 0)
				);
			}
			return 0;
		},
		calculateSeriesNeededDays(item) {
			if (!item || item.일판매수량 <= 0) return '0일치';

			const 가용재고 = item.재고 + item.구매 + item.배대지 + item.통관중 + item.입고중 + item.국내재고;
			let series남은재고기간 = 0;
			let series잔여가용재고 = 가용재고;

			for (const 예상판매량 of item.forecastSeries) {
				if (series잔여가용재고 >= 예상판매량) {
					series잔여가용재고 -= 예상판매량;
					series남은재고기간++;
				} else {
					break;
				}
			}

			if (series남은재고기간 <= this.baseSettings.safetyDays) {
				return `${this.baseSettings.targetDays - this.baseSettings.safetyDays}일치`;
			} else if (series남은재고기간 < this.baseSettings.targetDays) {
				return `${this.baseSettings.targetDays - series남은재고기간}일치`;
			}
			return '0일치';
		},
		calculateNeededStockDays(item) {
			if (!item || !item.forecastAI || item.forecastAI.length === 0 || item.일판매수량 <= 0) {
				return '0일치';
			}

			const 가용재고 = item.재고 + item.구매 + item.배대지 + item.통관중 + item.입고중 + item.국내재고;
			let ai남은재고기간 = 0;
			let ai잔여가용재고 = 가용재고;

			for (const 예상판매량 of item.forecastAI) {
				if (ai잔여가용재고 >= 예상판매량) {
					ai잔여가용재고 -= 예상판매량;
					ai남은재고기간++;
				} else {
					break;
				}
			}

			if (ai남은재고기간 <= this.baseSettings.safetyDays) {
				return `${this.baseSettings.targetDays - this.baseSettings.safetyDays}일치`;
			} else if (ai남은재고기간 < this.baseSettings.targetDays) {
				return `${this.baseSettings.targetDays - ai남은재고기간}일치`;
			}
			return '0일치';
		},
		calculateForecastQuantities(item) {
			if (item.forecastSeries) {
				item.forecastSeriesQty = item.forecastSeries.slice(0, 30).reduce((pv,cv) => pv + cv, 0);
			}
			if (item.forecastAI) {
				item.forecastAIQty = item.forecastAI.slice(0, 30).reduce((pv,cv) => pv + cv, 0);
			}
			return item;
		},
		extractOfferIdFromUrl(url) {
			const match = url.match(/offer\/(\d+)\.html/);
			return match ? match[1] : null;
		},
		editlists() {
			if(this.tableSelectedItem.length == 0) {
				if(!confirm('전체상품에 변경된 내용을 적용하시겠습니까?'))
				return;
			}
			
			const itemsToUpdate = this.tableSelectedItem.length > 0 ? 
				this.tableSelectedItem : this.saleslists;

			for(const item of itemsToUpdate) {
				if(this.입고기간) {
					item.입고기간 = this.입고기간;
				}
				if(this.최소재고기간) {
					item.최소재고기간 = this.최소재고기간;
				}
				if(this.최대재고기간) {
					item.최대재고기간 = this.최대재고기간;
				}
				
				item.isModified = true;
				
				// 새로운 메트릭 계산
				this.calculateInventoryMetrics(item);
			}

			this.입고기간 = '';
			this.최소재고기간 = '';
			this.최대재고기간 = '';
		},
		async saveSales(){
			const modifiedItems = this.saleslists.filter(item => item.isModified);
			if(modifiedItems.length === 0){
				alert('변경된 항목이 없습니다.');
				return;
			}
			this.updateSaleslist(modifiedItems, true);
		},
		async changeMemo(event,saleslist){
			if(event.keyCode == 13){
				this.updateSaleslist([saleslist],true)
			}
		},
		async updateSaleslist(arr, bool){
			console.log(arr)
			try{
				// isModified 플래그 제외하고 새로운 배열 생성
				const dataToSend = arr.map((item) => {
					const newItem = { ...item };
					delete newItem.isModified;
					return newItem;
				});

				var result = await axios.post('/api/updateOnlyGrowthSalesV2', dataToSend);
				if(result.data.res == 'success'){
					arr.forEach(item => {
						item.isModified = false;
					});
					if(bool){
						alert('저장 완료');
					}
					// 저장이 성공한 항목들의 isModified 플래그를 false로 초기화
					return true
					
				} else {
					if(bool){
						alert(result.data.alert);
					}
					console.log(result.data)
					return false
				}
			} catch(err){
				alert('저장 중 오류가 발생했습니다.');
				console.error(err);
				return false
			}
		},
		async getKeywordData(dates, keyword) {
			try {
				const yearlyData = [];
				const currentYear = new Date().getFullYear();
				
				for (let yearOffset = 1; yearOffset <= 3; yearOffset++) {
					try {
						const offsetDates = dates.map(date => {
							const offsetDate = new Date(date);
							offsetDate.setFullYear(offsetDate.getFullYear() - yearOffset);
							return offsetDate.toISOString().split('T')[0];
						});

						console.log(`Fetching data for year ${currentYear - yearOffset}`);
						
						const result = await axios.post('/api/getDatalabApi', {
							clientId: this.clientId,
							clientSecret: this.clientSecret,
							dates: offsetDates,
							keyword: keyword
						});

						if (result.data.요청결과 === 'success' && result.data.result.results[0].data.length > 0) {
							yearlyData.push({
								year: currentYear - yearOffset,
								data: result.data.result.results[0].data
							});
							console.log(`Successfully fetched ${result.data.result.results[0].data.length} records`);
						} else {
							console.log(`No data available for year ${currentYear - yearOffset}`);
						}
						
						// API 호출 간 딜레이 추가 (필요한 경우)
						await new Promise(resolve => setTimeout(resolve, 1000));

					} catch(yearError) {
						console.error(`Error fetching data for year ${currentYear - yearOffset}:`, yearError);
						// 개별 연도 실패해도 계속 진행
						continue;
					}
				}

				if (yearlyData.length === 0) {
					console.warn('No data available for any year');
					return [];
				}

				const mergedData = yearlyData.flatMap(yearly => 
					yearly.data.map(item => ({
						...item,
						year: yearly.year
					}))
				);

				console.log(`Total merged records: ${mergedData.length}`);
				return mergedData;

			} catch(e) {
				console.error('Error in getKeywordData:', e);
				return [];
			}
		},
		async getForecastAI(keywordForecast, f) {
			if (!keywordForecast || keywordForecast.length === 0) return [];

			// 1. 날짜별 3개년 데이터 그룹화 (월-일 기준)
			const groupedByDate = {};
			keywordForecast.forEach(data => {
				const date = new Date(data.period);
				const monthDay = `${date.getMonth()}-${date.getDate()}`; // 월-일 형식
				const year = date.getFullYear();
				
				if (!groupedByDate[monthDay]) {
					groupedByDate[monthDay] = {
						years: {},
						ratios: []
					};
				}
				
				groupedByDate[monthDay].years[year] = data.ratio;
				groupedByDate[monthDay].ratios.push(data.ratio);
			});

			// 2. 각 날짜별 패턴 분석
			const datePatterns = {};
			Object.entries(groupedByDate).forEach(([monthDay, data]) => {
				const values = Object.values(data.years);
				const mean = values.reduce((a, b) => a + b, 0) / values.length;
				
				// 연도별 일관성 체크 (표준편차의 변동계수로 판단)
				const stdDev = Math.sqrt(values.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / values.length);
				const cv = stdDev / mean; // 변동계수 (Coefficient of Variation)
				
				datePatterns[monthDay] = {
					mean,
					stdDev,
					cv,
					values,
					// 연도별로 비슷한 패턴을 보이는지 (변동계수가 0.5 이하면 일관된 패턴으로 판단)
					isConsistent: cv <= 0.5,
					// 전체 평균대비 얼마나 높은지
					relativeStrength: null // 후에 계산
				};
			});

			// 3. 전체 평균 대비 각 날짜의 상대적 강도 계산
			const overallMean = Object.values(datePatterns)
				.reduce((sum, pattern) => sum + pattern.mean, 0) / Object.keys(datePatterns).length;
			
			Object.keys(datePatterns).forEach(monthDay => {
				datePatterns[monthDay].relativeStrength = datePatterns[monthDay].mean / overallMean;
			});

			// 4. 판매 데이터 분석
			const salesData = f.salesHistory;
			const nonZeroSales = salesData.filter(x => x > 0);
			if (nonZeroSales.length === 0) return [];
			
			const salesMean = nonZeroSales.reduce((sum, val) => sum + val, 0) / nonZeroSales.length;

			// 5. 예측값 계산
			const forecastAI = [];
			const futureDates = f.dates.slice(salesData.length);

			futureDates.forEach(date => {
				const dateObj = new Date(date);
				const monthDay = `${dateObj.getMonth()}-${dateObj.getDate()}`;
				const pattern = datePatterns[monthDay];

				if (pattern) {
					let predictedSales = salesMean;
					
					if (pattern.isConsistent) {
						// 연도별로 일관된 패턴을 보이는 경우
						predictedSales *= pattern.relativeStrength;
						
						// 변동성이 큰 경우 신뢰구간 적용
						if (pattern.cv > 0.3) {
							// 변동성이 클수록 보수적으로 예측
							const confidenceFactor = 1 - (pattern.cv * 0.5);
							predictedSales *= confidenceFactor;
						}
					}
					
					forecastAI.push(Math.max(0, Math.round(predictedSales)));
				} else {
					forecastAI.push(Math.round(salesMean));
				}
			});

			// 6. 이동평균으로 스무딩 처리 (급격한 변동 완화)
			const smoothedForecast = [];
			const windowSize = 7; // 7일 이동평균

			for (let i = 0; i < forecastAI.length; i++) {
				const window = forecastAI.slice(Math.max(0, i - windowSize + 1), i + 1);
				const average = window.reduce((sum, val) => sum + val, 0) / window.length;
				smoothedForecast.push(Math.round(average));
			}

			return smoothedForecast;
		},
		async saveAPI(){
			try{
				var result = await axios.post('/api/setDatalabApi',{clientId:this.clientId,clientSecret:this.clientSecret});
				if(result.data.요청결과 == 'success'){
					var obj = this.deepClone(this.$store.state.settings[0]);
					obj.clientId = this.clientId;
					obj.clientSecret = this.clientSecret;
					this.$store.commit('settingsupdate',[obj])
					alert('저장완료')
				} else {
					alert('저장실패(510)');
					console.log(result.data.result);
				}
			}catch(e){
				alert('저장실패(514)')
				console.log(e)
			}
		},
		styleDday(dday){
			if(dday > 0){
				return "width:80px;overflow:hidden;text-overflow: ellipsis;color:red;font-weight:bold"
			} else {
				return "width:80px;overflow:hidden;text-overflow: ellipsis;"
			}
		},
		changePoEnter(event,saleslist){
            const index = this.saleslists.findIndex(i => i.vendorItemId === saleslist.vendorItemId);
			if(event.keyCode === 13){
				this.changePo(saleslist,index);
			}
		},
		async changePo(saleslist, index) {
			try {
				// 1. 기본 input 값 변환
				saleslist.구매 = parseInt(saleslist.구매) || 0;
				saleslist.배대지 = parseInt(saleslist.배대지) || 0;
				saleslist.통관중 = parseInt(saleslist.통관중) || 0;
				saleslist.입고중 = parseInt(saleslist.입고중) || 0;
				saleslist.국내재고 = parseInt(saleslist.국내재고) || 0;
				
				// 2. 타겟키워드 처리
				if (saleslist.타겟키워드) {
					const sameProductItems = this.saleslists.filter(item => 
						item.productId === saleslist.productId
					);
					
					if (this.saleslistsinit[index].타겟키워드 !== saleslist.타겟키워드) {
						const keywordForecast = await this.getKeywordData(saleslist.dates, saleslist.타겟키워드);
						if (keywordForecast?.length > 0) {
							for (const item of sameProductItems) {
								item.타겟키워드 = saleslist.타겟키워드;
								item.keywordForecast = keywordForecast;
								item.forecastAI = await this.getForecastAI(keywordForecast, item);
								
								// 메트릭 재계산
								this.calculateInventoryMetrics(item);
								
								// saleslistsinit 업데이트
								const initIndex = this.saleslistsinit.findIndex(i => i.vendorItemId === item.vendorItemId);
								if (initIndex !== -1) {
									this.saleslistsinit[initIndex].타겟키워드 = item.타겟키워드;
								}
							}
						}
					}
				} else {
					const sameProductItems = this.saleslists.filter(item => 
						item.productId === saleslist.productId
					);
					
					for (const item of sameProductItems) {
						item.타겟키워드 = '';
						item.keywordForecast = [];
						item.forecastAI = [];
						
						// 메트릭 재계산
						this.calculateInventoryMetrics(item);
						
						const initIndex = this.saleslistsinit.findIndex(i => i.vendorItemId === item.vendorItemId);
						if (initIndex !== -1) {
							this.saleslistsinit[initIndex].타겟키워드 = '';
						}
					}
				}

				// 3. 동일 구매링크 상품 처리 (계속)
				const dbItem = this.$store.state.growthdb.find(x => x.vendorItemId === saleslist.vendorItemId);
				if (dbItem?.구매정보?.[0]?.구매링크) {
					const offerId = this.extractOfferIdFromUrl(dbItem.구매정보[0].구매링크);
					if (offerId) {
						const sameGroupItems = this.saleslists.filter(item => {
							const otherDbItem = this.$store.state.growthdb.find(x => x.vendorItemId === item.vendorItemId);
							if (!otherDbItem?.구매정보?.[0]?.구매링크) return false;
							return this.extractOfferIdFromUrl(otherDbItem.구매정보[0].구매링크) === offerId;
						});

						for (const groupItem of sameGroupItems) {
							this.calculateInventoryMetrics(groupItem);
						}
					}
				}

				// 4. 차트 업데이트
				this.$nextTick(() => {
					if (this.$refs.mainChart && this.$refs.mainChart.chart) {
						this.$refs.mainChart.chart.updateOptions(saleslist.chart);
					}
				});

			} catch (error) {
				console.error('Error in changePo:', error);
				alert('처리 중 오류가 발생했습니다.');
			}
		},
		generateChartData(item, forecastSeries, forecastAI, dates) {
			return {
				series: [
					{
						name: '예상 판매수량(series)',
						data: item.salesHistory.concat(forecastSeries).map(val => val || 0),
						type: 'line',
						yAxisIndex: 0
					},
					{
						name: '예상 판매수량(AI+키워드추세)',
						data: item.salesHistory.concat(forecastAI).map(val => val || 0),
						type: 'line',
						yAxisIndex: 0
					},
					{
						name: '방문자수',
						data: item.visitorHistory.map(val => val || 0),
						type: 'line',
						yAxisIndex: 1
					},
					{
						name: '페이지뷰',
						data: item.pageViewHistory.map(val => val || 0),
						type: 'line',
						yAxisIndex: 1
					},
					{
						name: '장바구니',
						data: item.cartHistory.map(val => val || 0),
						type: 'line',
						yAxisIndex: 1
					},
					{
						name: '주문수',
						data: item.orderHistory.map(val => val || 0),
						type: 'line',
						yAxisIndex: 1
					},
					{
						name: '전환율(%)',
						data: item.pvToOrder.map(val => val || 0),
						type: 'line',
						yAxisIndex: 2
					}
				],
				chart: {
					type: 'line',
					height: 500,
					width: 1200,
					animations: {
						enabled: false
					}
				},
				colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a'],
				stroke: {
					width: [2, 2, 2, 2, 2, 2, 2],
					curve: 'smooth'
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 3
				},
				xaxis: {
					categories: dates.map(date => date || ''),
					title: {
						text: '날짜'
					}
				},
				yaxis: [
					{
						min: 0,
						max: Math.max(...[
							...item.salesHistory,
							...item.salesHistory.concat(forecastSeries),
							...item.salesHistory.concat(forecastAI)
						].map(val => val || 0)) * 1.1 || 100,
						title: {
							text: '판매수량'
						},
						labels: {
							formatter: function(val) {
								return val ? Math.round(val).toString() : '0'
							}
						}
					},
					{
						min: 0,
						max: Math.max(...[
							...item.visitorHistory,
							...item.pageViewHistory,
							...item.cartHistory,
							...item.orderHistory
						].map(val => val || 0)) * 1.1 || 100,
						title: {
							text: '트래픽/주문'
						},
						opposite: true,
						labels: {
							formatter: function(val) {
								return val ? Math.round(val).toString() : '0'
							}
						}
					},
					{
						opposite: true,
						min: 0,
						max: Math.max(...item.pvToOrder.map(val => val || 0)) * 1.1 || 1,
						title: {
							text: '전환율(%)',
							offsetX: -20
						},
						labels: {
							formatter: function(val) {
								return typeof val === 'number' ? val.toFixed(2) + '%' : '0%'
							},
							offsetX: -30
						},
						tickAmount: 5,
						forceNiceScale: true,
						decimalsInFloat: 2
					}
				],
				tooltip: {
					shared: true,
					intersect: false,
					y: [{
						formatter: function(y) {
							return typeof y === 'number' ? Math.round(y).toString() : '0'
						}
					}, {
						formatter: function(y) {
							return typeof y === 'number' ? Math.round(y).toString() : '0'
						}
					}, {
						formatter: function(y) {
							return typeof y === 'number' ? Math.round(y).toString() : '0'
						}
					}, {
						formatter: function(y) {
							return typeof y === 'number' ? Math.round(y).toString() : '0'
						}
					}, {
						formatter: function(y) {
							return typeof y === 'number' ? Math.round(y).toString() : '0'
						}
					}, {
						formatter: function(y) {
							return typeof y === 'number' ? Math.round(y).toString() : '0'
						}
					}, {
						formatter: function(y) {
							return typeof y === 'number' ? y.toFixed(2) + '%' : '0%'
						}
					}]
				},
				legend: {
					position: 'top',
					horizontalAlign: 'left'
				}
			};
		},
		getMonthDates(prev, period) {
			const dateArray = [];
			const today = new Date();

			// prev 일 전의 날짜부터 시작
			let startDate = new Date(today);
			startDate.setDate(today.getDate() - prev);

			for (let i = 0; i < prev+period; i++) {
				let date = new Date(startDate);
				date.setDate(startDate.getDate() + i);
				dateArray.push(date.toISOString().split('T')[0]);
			}

			return dateArray;
		},
		collectGrowthSales() {
            window.postMessage({ greeting: "collectGrowthSales", companyid: this.$store.state.user.companyid, suppliercode: this.$store.state.user.suppliercode, growthSales:this.$store.state.growthSales}, "*",)
            this.showspinner1 = true;
            this.showlist = false;
        },
		changeleadtime(saleslist){
			var obj = {
				vendorItemId : saleslist.vendorItemId,
				입고리드타임 : saleslist.입고리드타임
			}
			if(this.$store.state.user.companyid){
				axios.post('/api/ai/updateleadtime',{companyid:this.$store.state.user.companyid, vendorItemId:obj.vendorItemId, 입고리드타임:obj.입고리드타임})
				.then(result => {
					console.log(result);
					if(result.data.요청결과 == 'ok'){
						this.$store.commit('updateleadtime',obj)
					} else {
						alert('저장실패(276)');console.log(result)
					}
				})
				.catch(e => {alert('저장실패(279)');console.log(e)})
			}
		},
		clickleadtime(saleslist){
			if(saleslist.showleadtime){
				saleslist.showleadtime = false;
			}
		},
		showtotal(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit;
			setTimeout(()=>{this.showlist = true;},10)
		},
		showReturn(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.returnProducts && e.returnProducts.length > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showNeedReturn(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showMonthlyCntOver0(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.월판매수량 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showNeedPo() {
			this.showlist = false;
			
			// 1. D-Day가 0 이상이거나 구매필요수량이 0보다 큰 항목 필터링
			let filteredList = this.saleslistsinit.filter(e => e.dday >= 0 || e.구매필요수량 > 0);
			
			// 2. productId로 그룹화
			const groupedByProduct = {};
			filteredList.forEach(item => {
				if (!groupedByProduct[item.productId]) {
					groupedByProduct[item.productId] = [];
				}
				groupedByProduct[item.productId].push(item);
			});
			
			// 3. 각 그룹의 최대 D-Day를 기준으로 그룹 정렬
			// D-Day가 음수인 경우는 구매필요수량을 고려
			const sortedList = Object.entries(groupedByProduct)
				.sort(([, groupA], [, groupB]) => {
					// 각 그룹의 최대 D-Day 계산
					const maxDdayA = Math.max(...groupA.map(item => item.dday));
					const maxDdayB = Math.max(...groupB.map(item => item.dday));
					
					// D-Day가 모두 음수인 경우 구매필요수량으로 비교
					if (maxDdayA < 0 && maxDdayB < 0) {
						const maxNeededQtyA = Math.max(...groupA.map(item => item.구매필요수량));
						const maxNeededQtyB = Math.max(...groupB.map(item => item.구매필요수량));
						return maxNeededQtyB - maxNeededQtyA;
					}
					return maxDdayB - maxDdayA;
				})
				.flatMap(([, group]) => {
					// 각 그룹 내에서 D-Day 순으로 정렬하되,
					// D-Day가 음수인 경우는 구매필요수량으로 정렬
					return group.sort((a, b) => {
						if (a.dday < 0 && b.dday < 0) {
							return b.구매필요수량 - a.구매필요수량;
						}
						return b.dday - a.dday;
					});
				});

			this.saleslists = sortedList;
			setTimeout(() => { this.showlist = true; }, 10);
		},
		showNeedPriceAdjust(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.가격조정필요 === true)
			setTimeout(()=>{this.showlist = true;},10)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		async search(){
			this.showspinner1 = true;
			this.loadingtext = '로켓그로스 판매현황을 불러오는 중입니다.'
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);
			var updateDate = `${year}-${month}-${day}`;

			if(this.$store.state.growthSales.length > 0){
				this.searchresult(this.$store.state.growthSales);
			} else {
				try{
					if(this.$store.state.growthdb.length == 0){
						try{
							var result = await axios.post('/api/getGrowthDBV2');
							if(result.data?.growthdb?.length == 0){
								alert('로켓그로스 판매현황/SCM을 이용하기전에 로켓그로스 상품DB를 먼저 수집해주세요')
								this.$router.push({name:'growth.growthdb'})
								return
							}
							this.$store.commit('growthdbupdate', result.data.growthdb);
						}catch(e){
							console.log('로켓그로스 상품DB 불러오기 실패')
							console.log(e)
						}                
					}
					result = await axios.post('/api/getGrowthSalesV2');

					if(result.data.res == 'success'){
						if(this.$store.state.growthdb.length > 0){
							// var growthSales = result.data.growthSales.filter(e => this.$store.state.growthdb?.find(f => f.vendorItemId == e.vendorItemId)?.show);
							var growthSales = result.data.growthSales;
						} else {
							growthSales = result.data.growthSales
						}

						if(!growthSales.find(e => e.updateDate == updateDate)){
							alert('로켓그로스 판매현황을 수집해주세요')
						}

						this.$store.commit('growthSalesUpdate',growthSales);
						this.searchresult(growthSales);
					} else {
						alert('로켓그로스 판매현황 가져오기 실패')
						console.log(result.data)
						this.showlist = false;
						this.showspinner = false;
						this.showspinner1 = false;
					}
				} catch(e) {
					alert('로켓그로스 판매현황 가져오기 실패')
					console.log(e)
					this.showlist = false;
					this.showspinner = false;
					this.showspinner1 = false;
				}
			}
		},
		generateDatesFromUpdateDate(updateDate) {
			if (!updateDate) return [];
			const baseDate = new Date(updateDate);
			// updateDate의 전날을 기준으로 설정
			baseDate.setDate(baseDate.getDate() - 1);
			const dates = [];
			// 이전 30일을 생성 (오름차순 정렬)
			for (let i = 29; i >= 0; i--) {
				const d = new Date(baseDate);
				d.setDate(baseDate.getDate() - i);
				dates.push(d.toISOString().split('T')[0]);
			}
			return dates;
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
		async searchresult(arr) {
			console.log(arr)
			if (arr.length === 0) {
				alert('로켓그로스 판매현황이 없습니다.');
				this.showlist = false;
				this.showspinner = false;
				this.showspinner1 = false;
				return;
			}

			this.needPo = 0;
			this.saleslists = [];
			this.saleslistsinit = [];

			arr.sort((a, b) => b.월판매수량 - a.월판매수량);

			for (const e of arr) {
				// 최대입고기간과 보관료 계산
				if (e.inboundInfo?.cfsStowedDetailList?.length > 0) {
					var oldestDate = new Date(e.inboundInfo.cfsStowedDetailList[0].inboundDate);
					var today = new Date();
					var daysAgo = Math.floor((today - oldestDate) / (1000 * 60 * 60 * 24));
					e.최대입고기간 = daysAgo;
					e.보관료 = parseFloat(e.inventoryDetails?.storageFee?.monthlyStorageFeeAmount?.amount || 0);

					if (e.returnProducts?.length > 0) {
						for (var a of e.returnProducts) {
							if (a.inboundInfo?.cfsStowedDetailList?.length > 0) {
								oldestDate = new Date(a.inboundInfo.cfsStowedDetailList[0].inboundDate);
								daysAgo = Math.floor((today - oldestDate) / (1000 * 60 * 60 * 24));
								a.최대입고기간 = daysAgo;
								a.보관료 = parseFloat(a.inventoryDetails?.storageFee?.monthlyStorageFeeAmount?.amount || 0);
							} else {
								a.최대입고기간 = 0;
								a.보관료 = 0;
							}
						}
					}
				} else {
					e.최대입고기간 = 0;
					e.보관료 = 0;
				}

				// mean과 forecastSeries 계산
				let startIndex = e.salesHistory.findIndex(element => element !== 0);
				let mean;
				if (startIndex === -1) {
					mean = 0;
				} else {
					let sum = 0;
					for (let i = startIndex; i < e.salesHistory.length; i++) {
						sum += e.salesHistory[i];
					}
					mean = sum / (e.salesHistory.length - startIndex);
				}

				// console.log(e)
				const maxDays = Math.max(parseInt(e.입고기간 ? e.입고기간 : this.baseSettings.inputDays) + (e.최대재고기간 ? e.최대재고기간 : this.baseSettings.targetDays), 30);
				// console.log(maxDays)
				const forecastSeries = Array(maxDays).fill(mean);
				// console.log(forecastSeries)

				// 기존 데이터 확인 및 설정값 적용
				if (this.$store.state.growthSales.length > 0 && 
					this.$store.state.growthSales.find(f => f.vendorItemId == e.vendorItemId)) {
					const f = this.$store.state.growthSales.find(f => f.vendorItemId == e.vendorItemId);
					var 최대재고기간 = parseInt(f.최대재고기간);
					var 최소재고기간 = parseInt(f.최소재고기간);
					var 입고기간 = parseInt(f.입고기간);
					var 구매 = parseInt(f.구매);
					var 배대지 = parseInt(f.배대지);
					var 통관중 = f.통관중 ? parseInt(f.통관중) : 0;
					var 국내재고 = f.국내재고 ? parseInt(f.국내재고) : 0;
					var 메모 = f.메모;

					// 날짜 관련 처리 
					var dates = this.getMonthDates(e.salesHistory.length, maxDays);

					if (f.타겟키워드) {
						var 타겟키워드 = f.타겟키워드;
						if (dates !== f.dates && this.clientId && this.clientSecret) {
							var keywordForecast = await this.getKeywordData(dates, 타겟키워드);
							var forecastAI = await this.getForecastAI(keywordForecast, f);
						} else {
							keywordForecast = f.keywordForecast;
							forecastAI = f.forecastAI;
						}
					} else {
						타겟키워드 = '';
						keywordForecast = [];
						forecastAI = [];
					}
				} else {
					타겟키워드 = '';
					입고기간 = this.baseSettings.inputDays;
					최대재고기간 = this.baseSettings.targetDays;
					최소재고기간 = this.baseSettings.safetyDays;
					keywordForecast = [];
					forecastAI = [];
					구매 = 0;
					배대지 = 0;
					통관중 = 0;
					국내재고 = 0;
					메모 = '';
				}

				dates = e.updateDate ? this.generateDatesFromUpdateDate(e.updateDate) : this.getMonthDates(e.salesHistory.length, maxDays);

				// 임시 데이터 객체 생성
				const itemData = {
					...e,
					dates,
					구매,
					배대지,
					통관중,
					국내재고,
					입고기간,
					최대재고기간,
					최소재고기간,
					chart: null,
					forecastSeries,
					forecastAI,
					keywordForecast,
					타겟키워드,
					메모,
					일판매수량: e.일판매수량,
					isModified: false
				};

				// calculateInventoryMetrics를 사용하여 계산 
				const calculatedMetrics = this.calculateInventoryMetrics(itemData);

				// 차트 데이터 생성
				const chart = this.generateChartData(itemData, forecastSeries, forecastAI, dates);

				// 최종 데이터 객체 생성
				const finalItemData = {
					...itemData,
					...calculatedMetrics,
					chart,
					forecastSeriesQty: forecastSeries.slice(0, 30).reduce((pv,cv) => pv+cv, 0),
					forecastAIQty: forecastAI.reduce((pv,cv) => pv + cv, 0)
				};

				this.saleslists.push(finalItemData);
				this.saleslistsinit.push(this.deepClone(finalItemData));
			}

			// 통계 업데이트
			this.needPo = this.saleslists.filter((item) => item.dday > 0).length;
			this.totalCnt = this.saleslists.length;
			this.returnProducts = this.saleslistsinit.filter(e => e.returnProducts?.length > 0).length;
			this.monthlyCntOver0 = this.saleslistsinit.filter(e => e.월판매수량 > 0).length;

			// UI 업데이트
			this.$nextTick(() => {
				this.showlist = true;
				this.showspinner = false;
				this.showspinner1 = false;
				this.loadingtext = '';
			});
			},
		calculateOldestAndTotalQty(data) {
			const today = new Date(); // 오늘 날짜 설정
			const thirtyDaysAgo = new Date();
			thirtyDaysAgo.setDate(today.getDate() - 30);

			let totalQty = 0;
			let oldestDate = null;
			let daysAgo = null;

			data.forEach(item => {
				const inboundDate = new Date(item.inboundDate);

				// 30일 이상 지난 항목 처리
				if (inboundDate < thirtyDaysAgo) {
					totalQty += item.remainingSkuQty; // 합산
					if (!oldestDate || inboundDate < oldestDate) {
						oldestDate = inboundDate; // 가장 오래된 날짜 업데이트
					}
				}
			});

			// 가장 오래된 날짜와 오늘의 차이를 계산
			if (oldestDate) {
				daysAgo = Math.floor((today - oldestDate) / (1000 * 60 * 60 * 24)); // 날짜 차이 계산
			}

			return {
				totalQty,
				oldestDaysAgo: daysAgo // 며칠 전인지 반환
			};
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		timestamptodate(timestamp){
			var date = new Date(timestamp);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		downloadsales() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			var obj = {};
			console.log(this.saleslists)
			this.saleslists.forEach(e => {
				obj = {
					SKUID : e.SKUID,
					옵션ID : e.vendorItemId,
					상품명 : e.판매상품명,
					옵션명 : e.옵션명,
					발주가능상태 : e.발주가능상태,
					구매필요수량 : e.구매필요수량,
					안전재고 : e.안전재고,
					쿠팡내재고 : e.재고,
					출고 : e.판매,
					입고리드타임 : e.입고리드타임,
					// 재고기준일 : e.재고기준일,
					일판매수량 : e.일판매수량
				}
				arr.push(obj)
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '발주수요예측')
			XLSX.writeFile(workBook, '발주수요예측' + '_' + getToday() + '.xlsx')
		},
		// 메소드를 호출하는 곳에서도 async/await 사용
		async setDateRange(item, days) {
			const today = new Date();
			const yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);
			
			const startDate = new Date(yesterday);
			startDate.setDate(yesterday.getDate() - days + 1);

			item.endDate = this.formatDate(yesterday);
			item.startDate = this.formatDate(startDate);

			await this.updateDateRange(item);
		},

		// formatDate(date) {
		// 	if (typeof date === 'string') {
		// 		date = new Date(date);
		// 	}
		// 	return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
		// },
		async updateDateRange(item) {
			if (item.startDate && item.endDate) {
				const startIndex = item.dates.indexOf(item.startDate);
				const endIndex = item.dates.indexOf(item.endDate);
				
				if (startIndex !== -1 && endIndex !== -1) {
					const filteredSalesHistory = item.salesHistory.slice(startIndex, endIndex + 1);
					
					// 일평균 판매량 재계산
					item.일판매수량 = filteredSalesHistory.reduce((sum, val) => sum + val, 0) / filteredSalesHistory.length;
					
					// 예측 데이터 업데이트
					const daysToForecast = Math.max(parseInt(item.입고기간) + this.baseSettings.targetDays, 30);
					item.forecastSeries = this.calculateForecast(filteredSalesHistory, daysToForecast);
					
					if (item.keywordForecast && item.keywordForecast.length > 0) {
						item.forecastAI = await this.getForecastAI(item.keywordForecast, item);
					}

					// 메트릭 재계산
					this.calculateInventoryMetrics(item);

					// 차트 업데이트
					this.$nextTick(() => {
						if (this.$refs.mainChart && this.$refs.mainChart.chart) {
							this.$refs.mainChart.chart.updateOptions(item.chart);
						}
					});
				}
			}
		},
		updateItemValues(item) {
			return this.calculateInventoryMetrics(item);
		},
		getUpdatedChartOption(item) {
			// console.log(item)
			const validateData = (data) => {
				if (!Array.isArray(data)) return Array(30).fill(0);
				return data.map(val => {
					const num = Number(val);
					return isNaN(num) ? 0 : num;
				});
			};

			const salesHistory = validateData(item.salesHistory);
			const visitorHistory = validateData(item.visitorHistory);
			const pageViewHistory = validateData(item.pageViewHistory);
			const cartHistory = validateData(item.cartHistory);
			const orderHistory = validateData(item.orderHistory);
			const pvToOrder = validateData(item.pvToOrder);
			// const forecastSeries = validateData(item.forecastSeries);
			// const forecastAI = validateData(item.forecastAI);

			// X축 범위 수정
			const displayDays = Math.max(30, parseInt(item.입고기간) + this.baseSettings.targetDays);
			const dates = item.dates.slice(0, displayDays);

			const salesMax = Math.max(
				...salesHistory,
				// ...forecastSeries,
				// ...forecastAI,
				1
			) * 1.1;

			const trafficMax = Math.max(
				...visitorHistory,
				...pageViewHistory,
				...cartHistory,
				...orderHistory,
				1
			) * 1.1;

			const conversionMax = Math.max(
				...pvToOrder,
				0.1
			) * 1.1;

			return {
				series: [
					{
						name: '판매량',
						data: salesHistory.map(val => val || 0),
						type: 'column'
					},
					{
						name: '방문자수',
						type: 'line',
						data: visitorHistory.map(val => val || 0)
					},
					{
						name: '페이지뷰',
						type: 'line',
						data: pageViewHistory.map(val => val || 0)
					},
					{
						name: '장바구니',
						type: 'line',
						data: cartHistory.map(val => val || 0)
					},
					{
						name: '주문건수',
						type: 'line',
						data: orderHistory.map(val => val || 0)
					},
					{
						name: '전환율(%)',
						type: 'line',
						data: pvToOrder.map(val => val || 0)
					}
				],
				chart: {
					height: 500,
					animations: { enabled: false },
					toolbar: {
						show: true
					},
					parentHeightOffset: 0,   // 부모 컨테이너와의 간격 제거
					offsetY: 0
				},
				plotOptions: {
					bar: {
						columnWidth: '30%',  // bar 너비 조절
						opacity: 0.4         // bar 투명도 조절
					}
				},
				stroke: {
					width: [0, 3, 3, 3, 3, 3],  // line 그래프 두께 증가
					curve: 'smooth'
				},
				colors: [
					'rgba(0, 143, 251, 0.5)',   // bar 차트 색상 (투명도 포함)
					'#00E396', 
					'#FEB019', 
					'#FF4560', 
					'#775DD0', 
					'#26a69a'
				],
				markers: {
					size: [0, 0, 0, 0, 0, 6],   // 전환율만 마커 표시
					colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#26a69a'],
					strokeColors: '#fff',
					strokeWidth: 2,
					hover: {
						size: 8
					}
				},
				xaxis: {
					categories: dates,
					labels: {
						style: { fontSize: '12px' }
					}
				},
				yaxis: [
					{
						seriesName: '판매량',
						title: { 
							text: '판매수량',
							offsetX:0,
							offsetY:-10
						},
						min: 0,
						max: salesMax,
						labels: {
							formatter: (val) => Math.round(val).toString()
						}
					},
					{
						seriesName: ['방문자수', '페이지뷰', '장바구니', '주문건수'],
						title: { text: '트래픽/주문' },
						min: 0,
						max: trafficMax,
						opposite: true,
						labels: {
							formatter: (val) => Math.round(val).toString()
						},
						show: true
					},
					{
						seriesName: '전환율(%)',
						title: {
							text: '전환율(%)',
							offsetX: -20
						},
						min: 0,
						max: conversionMax,
						opposite: true,
						labels: {
							formatter: (val) => val.toFixed(2) + '%',
							offsetX: -30
						},
						tickAmount: 5
					}
				],
				tooltip: {
					enabled: true,
					shared: true,
					intersect: false,
					followCursor: true,
					x: {
						show: true
					},
					y: [
						{
							title: {
								formatter: () => "판매량: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "방문자수: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "페이지뷰: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "장바구니: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "주문건수: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "전환율: "
							},
							formatter: (val) => val ? val.toFixed(2) + '%' : '0%'
						}
					],
					marker: {
						show: true
					},
					fixed: {
						enabled: false,
						position: 'topRight'
					}
				},
				dataLabels: { enabled: false },
				legend: {
					position: 'top',           // legend 위치를 상단으로
					horizontalAlign: 'center', // 가운데 정렬
					offsetY: 0,               // 상단 여백
					offsetX: 0,               // 좌우 여백
					floating: true,           // legend를 차트 영역 위에 띄움
					itemMargin: {
						horizontal: 15,
						vertical: 5
					},
					height: 30,              // legend 영역 높이
					fontSize: '13px',
					markers: {
						width: 12,
						height: 12,
						strokeWidth: 0,
						radius: 12
					}
				},
				grid: {
					padding: {
						top: 30,    // 상단 여백 추가
						bottom: 10   // 하단 여백
					}
				},
			};
		},
		resetChartData(item) {
			if (!item || !item.chart) return;

			const chartOption = this.getUpdatedChartOption(item);

			this.$nextTick(() => {
				if (this.$refs.mainChart && this.$refs.mainChart.chart) {
					this.$refs.mainChart.chart.updateOptions(chartOption, true, true);
				}
			});
		},
		calculateForecast(salesHistory, days) {
			const avgSales = salesHistory.reduce((sum, val) => sum + val, 0) / salesHistory.length;
			return Array(days).fill(avgSales);
		},
		closeModal(event, item) {
			event.preventDefault();
			if (this.$refs.mainChart && this.$refs.mainChart.chart) {
				this.$refs.mainChart.chart.destroy();
			}
			this.$nextTick(() => {
				this.$bvModal.hide('poQty' + item.vendorItemId)
			});
		},
		async calculateDailyStats() {
			try {
				const endDate = new Date();
				const startDate = new Date();
				startDate.setDate(startDate.getDate() - this.selectedPeriod);

				const dailyStats = [];
				for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
					const formattedDate = this.formatDate(d);
					let revenue = 0;
					let profit = 0;

					if (this.saleslistsinit && Array.isArray(this.saleslistsinit)) {
						this.saleslistsinit.forEach(sale => {
							if (!sale || !sale.salesHistory || !Array.isArray(sale.salesHistory)) {
								return;
							}

							const dayIndex = sale.dates.indexOf(d.toISOString().split('T')[0]);
							if (dayIndex >= 0) {
								const quantity = sale.salesHistory[dayIndex] || 0;
								const dbItem = this.$store.state.growthdb.find(x => x.vendorItemId === sale.vendorItemId);
								
								if (dbItem && typeof dbItem.salePrice === 'number' && typeof dbItem.마진 === 'number') {
									revenue += quantity * (dbItem.salePrice-dbItem.coupon);
									profit += quantity * dbItem.마진;
								}
							}
						});
					}

					dailyStats.push({
						date: formattedDate,
						revenue: Math.round(revenue),
						profit: Math.round(profit)
					});
				}

				this.dailyStats = dailyStats;
				await this.$nextTick();
				await this.updateRevenueChart();
				this.updateProfitTop10Chart();
				this.updateConversionChart(); // 전환율 차트 업데이트 추가
				this.updateMaxCpcChart();
			} catch (error) {
				console.error('Error calculating daily stats:', error);
				this.dailyStats = [];
			}
		},

		async updateRevenueChart() {
			try {
				if (!this.dailyStats || this.dailyStats.length === 0) {
					console.warn("일일 통계 데이터가 없습니다.");
					return;
				}

				// 데이터 준비
				const revenueData = this.dailyStats.map(day => ({
					x: day.date,
					y: day.revenue || 0
				}));

				const profitData = this.dailyStats.map(day => ({
					x: day.date,
					y: day.profit || 0
				}));

				const categories = this.dailyStats.map(day => day.date);

				// 새로운 옵션 설정
				const newOptions = {
					series: [{
						name: '매출',
						type: 'line',
						data: revenueData
					}, {
						name: '수익',
						type: 'line',
						data: profitData
					}],
					xaxis: {
						...this.revenueChartOption.xaxis,
						categories: categories
					}
				};

				// 차트 업데이트
				this.revenueChartOption = {
					...this.revenueChartOption,
					...newOptions
				};

				await this.$nextTick();
				const chartElement = document.querySelector('#revenue-chart');
				if (chartElement && chartElement.__vue__ && chartElement.__vue__.chart) {
					chartElement.__vue__.chart.updateOptions(this.revenueChartOption, false, true);
				}
			} catch (error) {
				console.error('차트 업데이트 중 오류 발생:', error);
			}
		},

		changePeriod(days) {
			this.selectedPeriod = days;
			this.calculateDailyStats();
		},

		// formatNumber(value) {
		// return new Intl.NumberFormat('ko-KR').format(Math.round(value));
		// },
		async uploadfile(event) {
            try {
                this.showlist = false;
                this.showuploadspinner = true;

                const file = event.target.files[0];
                const data = await this.readFileAsync(file);
                const workbook = XLSX.read(data, { type: 'binary' });

                for (const sheetName of workbook.SheetNames) {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    console.log(roa);

                    // Check if all options exist in growthdb
                    const invalidOption = roa.find(e => !this.$store.state.growthSales.find(ele => ele.vendorItemId === e.옵션ID));
                    if (invalidOption) {
                        alert(`상품DB에 ${invalidOption.옵션ID} 옵션ID가 존재하지 않습니다. 로켓그로스 상품DB와 를 다시 수집해주세요.`);
						continue;
                    }

                    let cntUpdate = 0;
                    const cnt = roa.length;
                    const errorArr = [];

                    for (const e of roa) {
                        const ob = this.deepClone(
                            this.$store.state.growthSales.find(ele => ele.vendorItemId === e.옵션ID)
                        );
                        console.log(ob, e);

						ob.구매 = parseInt(e.구매);
						ob.배대지 = parseInt(e.배대지);
						ob.통관중 = parseInt(e.통관중);
						ob.국내재고 = parseInt(e.국내재고);

                        const success = await this.updateSaleslist([ob], false);
                        if (success) {
                            cntUpdate++;
                        } else {
                            errorArr.push(e.옵션ID);
                        }
                    }

                    if (cntUpdate === cnt) {
                        alert('모든 데이터가 성공적으로 업데이트되었습니다.');
                    } else {
                        alert(`${errorArr.join(',')} 옵션번호는 상품DB 정보가 없어 업데이트에 실패했습니다.`);
                    }
                    this.showuploadspinner = false;
                    this.$bvModal.hide('excelupload');
					this.$store.commit('growthSalesUpdate', []);
                    this.search()
                }
            } catch (error) {
                alert(error.message);
                this.showuploadspinner = false;
                this.$bvModal.hide('excelupload');
				this.$store.commit('growthSalesUpdate', []);
                this.search()
            }
        },
        async readFileAsync(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result);
                reader.onerror = (error) => reject(error);
                reader.readAsBinaryString(file);
            });
        },
		// 날짜 포맷팅
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleDateString('ko-KR', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit'
			});
		},

		// 숫자 포맷팅
		formatNumber(number) {
			return new Intl.NumberFormat('ko-KR').format(number);
		},

		// 통화 포맷팅
		formatCurrency(amount) {
			return new Intl.NumberFormat('ko-KR', {
				style: 'currency',
				currency: 'KRW'
			}).format(amount);
		},

		// 입고 경과일 계산
		calculateDaysAgo(inboundDate) {
			const today = new Date();
			const date = new Date(inboundDate);
			return Math.floor((today - date) / (1000 * 60 * 60 * 24));
		},

		// 보관료 계산
		calculateStorageFee(inboundDate, quantity, storageFeeRates) {
			const daysStored = this.calculateDaysAgo(inboundDate);
			const rates = storageFeeRates.feeRatesAfterPromotion;
			
			const applicableRate = rates.find(rate => 
				daysStored >= rate.startDay && daysStored <= rate.endDay
			);

			return applicableRate ? (applicableRate.feeRate * quantity) : 0;
		},

		// 입고 리스트 정렬
		sortedInboundList(inboundInfo) {
			if (!inboundInfo?.cfsStowedDetailList) return [];
			return [...inboundInfo.cfsStowedDetailList].sort((a, b) => 
				new Date(a.inboundDate) - new Date(b.inboundDate)
			);
		},

		// 총 수량 계산
		getTotalQuantity(inboundInfo) {
			return inboundInfo?.cfsStowedDetailList?.reduce((sum, detail) => 
				sum + detail.remainingSkuQty, 0
			) || 0;
		},

		// 총 보관료 계산
		getTotalStorageFee(inboundInfo) {
			if (!inboundInfo?.cfsStowedDetailList) return 0;
			return inboundInfo.cfsStowedDetailList.reduce((sum, detail) => 
				sum + this.calculateStorageFee(
					detail.inboundDate, 
					detail.remainingSkuQty, 
					inboundInfo.storageFeeRates
				), 0
			);
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
/* .modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */
.list-group-item {
  min-height: auto;
  font-size: 0.9rem;
}

.list-group-item.border-bottom {
  border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

.font-weight-bold {
  font-weight: 600;
}
.summary-section {
    border-bottom: 1px solid #eee;
}
.summary-item {
    padding: 8px 0;
}
.summary-item small {
    font-size: 12px;
}
.card-title {
    font-size: 1rem;
    font-weight: 600;
}
</style>
